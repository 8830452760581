import { useEffect } from "react";

const Gallery = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
      document.title = 'MRCF | Gallery';
    },[])
    function shuffleArray(array) {
        for (var i = array.length - 1; i > 0; i--) {
 
            // Generate random number
            var j = Math.floor(Math.random() * (i + 1));
 
            var temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
 
        return array;
    }
    var arrtmp = []
    for(var i = 1; i < 61; i++) {
        arrtmp.push(i);
    }
    var t = shuffleArray(arrtmp)

    return (
        <>
        <section class="page-title-area">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 position-relative">
              <div class="page-title text-center">
                <h1>Gallery</h1>
              </div>
              <div class="breadcrumbs-area  text-center">
                <ol class="breadcrumbs">
                  <li>
                    <a href="#">Home</a>
                  </li>
                  <li class="active">Gallery</li>
                </ol>
              </div>
              <div class="back-home">
                <a href="/">
                  <i class="fa fa-arrow-left" aria-hidden="true"></i> BACK TO
                  HOME
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="our-gallery pt-80 pb-60">
        <div className="container">
          <div className="row">
            { 
            t.map((data, index) => <GalCard data={"/css/img/gallery/G(" + data + ").jpg"} key={index}/>)}
          </div>
        </div>
      </section>
        </>
    )
}

const GalCard = ({data}) => {
    return (
        <>
        <div className="col-lg-4 p-1">
              <img src = {data}/>
            </div>
        </>
    )
}

export default Gallery;