import React, {useEffect} from 'react';

const Contactus = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = 'MRCF | Contact Us';
      });
    return (
        <>
        <section class="page-title-area">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 position-relative">
                        <div class="page-title text-center">
                            <h1>Contact Us</h1>
                        </div>
                        <div class="breadcrumbs-area  text-center">
                            <ol class="breadcrumbs">
                                <li><a href="#">Home</a></li>
                                <li class="active">Contact Us</li>
                            </ol>
                        </div>
                        <div class="back-home">
                            <a href="index.html"><i class="fa fa-arrow-left" aria-hidden="true"></i> BACK TO HOME</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="keep-in-touch-area pt-90 pb-90">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="section-title text-center">
                            <h2>Keep In <span>Touch</span></h2>
                            <img src="/css/img/title-bottom.png" alt="" />
                            <p>At Motibhai R. Chaudhary Foundation, we believe in the power of connection and collaboration to bring about positive change. Our "Keep In Touch" section is dedicated to fostering ongoing communication and engagement with our valued supporters, volunteers, and stakeholders. We invite you to stay connected with us to receive regular updates on our impactful projects, inspiring success stories, and upcoming events. Through our newsletters, social media platforms, and interactive forums, we aim to keep you informed about our mission, achievements, and ways you can contribute further. Together, we can build a stronger community and work towards a better future. Join us in staying connected and making a lasting difference.</p>
                        </div>
                    </div>
                </div>
                <div class="row keep-in-touch-therd-box">
                    <div class="col-md-4">
                        <div class="single-box text-center">
                            <i class="fa fa-map" aria-hidden="true"></i>
                            <h3>ADDRESS</h3>
                            <p>“Moti Bhavan”, 1-8, Span Enclave, <br/>
B/F Akshardham Township, Opp. N.G. International School,
Vishnagar Road, Manav Ashram Chokadi, Mahesana, Gujarat 384001</p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="single-box text-center">
                            <i class="fa fa-phone" aria-hidden="true"></i>
                            <h3>CALL US</h3>
                            <p>+91 98253 28328<br/>+91 70163 11894<br/><br/><br/></p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="single-box text-center">
                            <i class="fa fa-envelope" aria-hidden="true"></i>
                            <h3>EMAIL US</h3>
                            <p>motibhaichaudharyfoundation@gmail.com<br/><br/><br/><br/></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>  
        <div class="google-map pb-90">
            {/* <div id="googleMap"></div> */}
            <div class = "container">
            <div class = "row gy-4 justify-content-center">
                    <div class = "col-md-12">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3551.5314989505605!2d72.41945702626049!3d23.61869436323776!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395c4352fcd1949d%3A0xf2d0429c5c0e5241!2sMOTIBHAI%20R.%20CHAUDHARY%20FOUNDATION!5e0!3m2!1sen!2sin!4v1687365269381!5m2!1sen!2sin" width="100%" height="500" style={{border: 0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
            </div>
        </div>
        </>
    )
}

export default Contactus;